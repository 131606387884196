
import { defineComponent, ref, unref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { getUser } from '@/services/api';

import { PartialUser, UserStatus } from '@/interfaces/User';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialUser & {} = {
  avatar: '',
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  email_verified_at: '',
  birthdate: '',
  status: UserStatus.ENABLE,
  created_at: ''
};

export default defineComponent({
  setup() {
    const userId = useRoute().params.id as string;
    const data = ref(DEFAULT_FORM_VALUES);

    onMounted(async() => {
      const res = await getUser({ userId });
      data.value = res.data;
    });

    return {
      data
    };
  }
});
